<template>
  <ol class="relative border-s border-gray-200 dark:border-gray-700">
    <li class="mb-10 ms-4">
      <div
        class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"
      ></div>
      <time
        class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
        >May 2019</time
      >
      <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
        Designer @ Hi Publicidade Digital
      </h3>
      <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">
        Social media, corporate design, and visual identities for clients.
      </p>
    </li>
    <li class="mb-10 ms-4">
      <div
        class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"
      ></div>
      <time
        class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
        >March 2021</time
      >
      <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
        Started studies @ UTFPR
      </h3>
      <p class="text-base font-normal text-gray-500 dark:text-gray-400">
        Internet Systems Technology course at the Federal Technological
      </p>
    </li>
    <li class="ms-4 mb-10">
      <div
        class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"
      ></div>
      <time
        class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
        >July 2023</time
      >
      <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
        Fullstack developer @ FiqOn
      </h3>
      <p class="text-base font-normal text-gray-500 dark:text-gray-400">
        System integrations between APIs. front development using Bootstrap
      </p>
    </li>
    <li class="ms-4 mb-10">
      <div
        class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"
      ></div>
      <time
        class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
        >March 2024</time
      >
      <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
        Systems Integration Project Manager @ FiqOn
      </h3>
      <p class="text-base font-normal text-gray-500 dark:text-gray-400">
        System integrations between APIs. front development using Bootstrap
      </p>
    </li>
    <li class="ms-4 mb-10">
      <div
        class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"
      ></div>
      <time
        class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500"
        >June 2024</time
      >
      <h3 class="text-lg font-semibold text-gray-900 dark:text-white">
        Frontend developer @ FiqOn
      </h3>
      <p class="text-base font-normal text-gray-500 dark:text-gray-400">
        Making complex interfaces with Vue.js
      </p>
    </li>
  </ol>
</template>

<script>
export default {
  name: "TimelineAbout",
  components: {},
};
</script>

<style scoped></style>
