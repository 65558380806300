<template>
  <div class="m-5 text-center lg:text-start">
    <h1 class="text-4xl md:text-6xl lg:text-7xl font-bold">Mirtha Klauck</h1>
    <p class="text-xl">
      Com mais de uma década de experiência, comecei confeccionando lençóis e
      fronhas e evoluí para uma modelista especializada em moda feminina,
      criando peças exclusivas e sob medida.
    </p>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style></style>
